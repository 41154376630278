import { GetTagsUseCase, ITagRepository, TAG_REPOSITORY, GET_TAGS_USE_CASE } from '@module/form';

export const tagUseCaseProviders = [
  {
    provide: GET_TAGS_USE_CASE,
    useFactory: (tagRepository: ITagRepository) => {
      return new GetTagsUseCase(tagRepository);
    },
    inject: [TAG_REPOSITORY],
  },
];
