import {
  ANSWER_COMMENT_USE_CASE,
  AnswerCommentUseCaseInput,
  AnswerCommentUseCaseOutput,
  AnswerType,
  Attendee,
  AttendeeSubmission,
  CREATE_ANSWER_COMMENT_USE_CASE,
  DecimalNumber,
  DELETE_ANSWER_COMMENT_USE_CASE,
  EssayAnswer,
  ExaminationAssignment,
  FillInBlankGroupAnswer,
  GET_ANSWERS_USE_CASE,
  GET_ASSIGNMENT_BY_ID_USE_CASE,
  GET_ATTENDEE_BY_ID_USE_CASE,
  GET_ATTENDEE_SUBMISSIONS_USE_CASE,
  GET_ATTENDEES_USE_CASE,
  GET_SUBMISSION_BY_ID_USE_CASE,
  GRADING_ANSWER_USE_CASE,
  GRADING_SUBMISSION_USE_CASE,
  GradingAnswerUseCaseInput,
  GradingAnswerUseCaseOutput,
  GradingSubmissionUseCaseOutput,
  HomeworkAssignment,
  MatchingAnswerGroup,
  MultipleChoiceAnswer,
  SingleChoiceAnswer,
  UPDATE_ANSWER_COMMENT_USE_CASE,
  UPDATE_SUBMISSION_USE_CASE,
  UpdateSubmissionUseCaseOutput,
} from '@module/assignment';
import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import {
  FIND_FORM_RELEASE_BY_ID_USE_CASE,
  FIND_QUESTION_RELEASES_USE_CASE,
  FormRelease,
  QuestionRelease,
  QuestionReleaseProps,
  QuestionReleases,
} from '@module/form';
import {
  AutoGradingUseCaseState,
  GetAssignmentByIdUseCaseState,
  GetAttendeeByIdUseCaseState,
  GetAttendeeSubmissionUseCaseState,
  GetAttendeesUseCaseState,
  GetFormReleaseByIdUseCaseState,
  GetQuestionReleasesUseCaseState,
  GetSubmissionAnswersUseCaseState,
  GetSubmissionByIdUseCaseState,
  GradingSubmissionState,
  UpdateAnswerUseCaseState,
  UpdateAttendeeGradedStateUseCaseState,
  UpdateSubmissionUseCaseState,
} from './grading-submission.states';

const DECIMAL_PLACES = 3;
const calculateTotalScore = (
  answers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[],
): number =>
  answers.reduce((totalScore, item) => {
    const isFillInBlankAnswer = item.type === AnswerType.FillInBlank;
    if (isFillInBlankAnswer) {
      const fillInBlankAnswer = item as FillInBlankGroupAnswer;

      let fillInBlankAnswerScore = 0;
      fillInBlankAnswer.answers.forEach((answer) => {
        if (answer.hasGraded()) {
          fillInBlankAnswerScore = fillInBlankAnswerScore + answer.score;
        }
      });

      return DecimalNumber.roundNumber(totalScore + fillInBlankAnswerScore, DECIMAL_PLACES);
    }

    const answer = item as SingleChoiceAnswer | MultipleChoiceAnswer | EssayAnswer;
    if (!answer.hasGraded()) {
      return totalScore;
    }
    return DecimalNumber.roundNumber(totalScore + answer.score, DECIMAL_PLACES);
  }, 0);

const calculateMaxTotalScore = (
  questionReleases: QuestionRelease<QuestionReleaseProps>[],
): number =>
  questionReleases.reduce((totalMaxScore, item) => {
    return totalMaxScore + item.score;
  }, 0);

const updateAnswers = (
  answers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[],
  answer:
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup,

  attendeeList: Attendee[],
  attendee?: Attendee,
): {
  updatedAnswers: (
    | EssayAnswer
    | SingleChoiceAnswer
    | FillInBlankGroupAnswer
    | MultipleChoiceAnswer
    | MatchingAnswerGroup
  )[];
  updatedAttendeeList: Attendee[];
} => {
  const updatedAnswerIndex = answers.findIndex((item) => item.questionId === answer.questionId);
  answers[updatedAnswerIndex] = answer;

  if (attendee) {
    const updatedAttendeeIndex = attendeeList.findIndex(
      (item) => item.id.toString() === attendee.id.toString(),
    );
    attendeeList[updatedAttendeeIndex] = attendee;
  }

  return {
    updatedAnswers: answers,
    updatedAttendeeList: attendeeList,
  };
};

const updateAttendeeList = (attendeeList: Attendee[], attendee: Attendee): Attendee[] => {
  return attendeeList.map((attendeeElement) => {
    if (attendeeElement.id.toString() === attendee.id.toString()) {
      return attendee;
    }
    return attendeeElement;
  });
};

export const handleGetAttendeesUseCase: UseCaseStateGenerator<GetAttendeesUseCaseState> = {
  name: GET_ATTENDEES_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAttendeeListStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const attendeeList = action.payload as Attendee[];

    return {
      ...state,
      attendeeList: attendeeList,
      getAttendeeListStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getAttendeeListStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      attendeeList: null,
      getAttendeeListStatus: RequestStatus.RESET,
    };
  },
};

export const handleGetAttendeeByIdUseCase: UseCaseStateGenerator<GetAttendeeByIdUseCaseState> = {
  name: GET_ATTENDEE_BY_ID_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAttendeeByIdStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    return {
      ...state,
      attendee: action.payload as Attendee,
      getAttendeeByIdStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error: error,
      getAttendeeByIdStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      attendee: null,
      getAttendeeByIdStatus: RequestStatus.RESET,
    };
  },
};

export const handleGetFormReleaseByIdUseCase: UseCaseStateGenerator<GetFormReleaseByIdUseCaseState> =
  {
    name: FIND_FORM_RELEASE_BY_ID_USE_CASE,
    executing: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getFormReleaseByIdStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const { payload } = action;

      return {
        ...state,
        formRelease: payload as FormRelease,
        getFormReleaseByIdStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const { error } = action;
      return {
        ...state,
        error,
        getFormReleaseByIdStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getFormReleaseByIdStatus: RequestStatus.RESET,
      };
    },
  };

export const handleGetAssignmentByIdUseCase: UseCaseStateGenerator<GetAssignmentByIdUseCaseState> =
  {
    name: GET_ASSIGNMENT_BY_ID_USE_CASE,
    executing: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getAssignmentByIdStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const { payload } = action;

      const assignment = payload as HomeworkAssignment | ExaminationAssignment;

      return {
        ...state,
        assignment,
        getAssignmentByIdStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const { error } = action;
      return {
        ...state,
        getAssignmentByIdStatus: RequestStatus.ERROR,
        error,
      };
    },
    reset: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getAssignmentByIdStatus: RequestStatus.RESET,
      };
    },
  };

export const handleGetSubmissionById: UseCaseStateGenerator<GetSubmissionByIdUseCaseState> = {
  name: GET_SUBMISSION_BY_ID_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getSubmissionByIdStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { payload } = action;
    return {
      ...state,
      submission: payload,
      getSubmissionByIdStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      getSubmissionByIdStatus: RequestStatus.ERROR,
      error,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getSubmissionByIdStatus: RequestStatus.RESET,
    };
  },
};

export const handleGetQuestionReleasesUseCase: UseCaseStateGenerator<GetQuestionReleasesUseCaseState> =
  {
    name: FIND_QUESTION_RELEASES_USE_CASE,
    executing: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getQuestionReleasesStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const questionReleases = action.payload as QuestionRelease<QuestionReleaseProps>[];
      const sortedQuestions = QuestionReleases.sortQuestions(questionReleases);

      const maxScore = calculateMaxTotalScore(questionReleases);
      return {
        ...state,
        questionReleases: sortedQuestions,
        maxScore,
        getQuestionReleasesStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const { error } = action;
      return {
        ...state,
        getQuestionReleasesStatus: RequestStatus.ERROR,
        error,
      };
    },
    reset: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getQuestionReleasesStatus: RequestStatus.RESET,
      };
    },
  };

export const handleGetAnswersUseCase: UseCaseStateGenerator<GetSubmissionAnswersUseCaseState> = {
  name: GET_ANSWERS_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAnswersStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const answers = action.payload as (
      | EssayAnswer
      | SingleChoiceAnswer
      | FillInBlankGroupAnswer
      | MultipleChoiceAnswer
      | MatchingAnswerGroup
    )[];

    const totalScore = calculateTotalScore(answers);

    return {
      ...state,
      answers,
      totalScore,
      getAnswersStatus: RequestStatus.SUCCESS,
    };
  },
  error: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { error } = action;
    return {
      ...state,
      error,
      getAnswersStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      getAnswersStatus: RequestStatus.RESET,
      answers: null,
      totalScore: null,
    };
  },
};

export const handleGetAttendeeSubmissionUseCase: UseCaseStateGenerator<GetAttendeeSubmissionUseCaseState> =
  {
    name: GET_ATTENDEE_SUBMISSIONS_USE_CASE,
    executing: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        getAttendeeSubmissionStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const attendeeSubmission = action.payload as AttendeeSubmission;
      return {
        ...state,
        attendeeSubmission,
        submission: attendeeSubmission?.submissions[0],
        getAttendeeSubmissionStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: {}, action: Action) => {
      const { error } = action;
      return {
        ...state,
        error: error,
        getAttendeeSubmissionStatus: RequestStatus.ERROR,
      };
    },
    reset: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        attendeeSubmission: null,
        submission: null,
        getAttendeeSubmissionStatus: RequestStatus.RESET,
      };
    },
  };

export const handleUpdateAttendeeGradedStateUseCase: UseCaseStateGenerator<UpdateAttendeeGradedStateUseCaseState> =
  {
    executing: (state: GradingSubmissionState): GradingSubmissionState => {
      return {
        ...state,
        updateAttendeeGradedStateStatus: RequestStatus.EXECUTE,
      };
    },
    success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
      const updatedAttendee = action.payload as Attendee;

      const foundUpdatedAttendeeIndex = state.attendeeList.findIndex(
        (attendee) => attendee.id.toString() === updatedAttendee.id.toString(),
      );

      state.attendeeList.splice(foundUpdatedAttendeeIndex, 1, updatedAttendee);

      return {
        ...state,
        attendeeList: state.attendeeList,
        updateAttendeeGradedStateStatus: RequestStatus.SUCCESS,
      };
    },
  };

export const handleGradingAnswersUseCase: UseCaseStateGenerator<UpdateAnswerUseCaseState> = {
  name: GRADING_ANSWER_USE_CASE,
  executing: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const useCaseInput = action.payload as GradingAnswerUseCaseInput;
    const { id } = useCaseInput;

    return {
      ...state,
      updateAnswerStatus: RequestStatus.EXECUTE,
      updatedAnswerId: id,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const { updatedAnswer, updatedAttendee } = action.payload as GradingAnswerUseCaseOutput;

    const { updatedAnswers, updatedAttendeeList } = updateAnswers(
      answers,
      updatedAnswer,
      attendeeList,
      updatedAttendee,
    );
    const totalScore = calculateTotalScore(updatedAnswers);

    return {
      ...state,
      updateAnswerStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
      totalScore,
      attendeeList: updatedAttendeeList,
      updatedAnswerId: '',
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateAnswerStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateAnswerStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const handleAnswerCommentUseCase: UseCaseStateGenerator<UpdateAnswerUseCaseState> = {
  name: ANSWER_COMMENT_USE_CASE,
  executing: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const useCaseInput = action.payload as AnswerCommentUseCaseInput;
    const { id } = useCaseInput;

    return {
      ...state,
      updateCommentStatus: RequestStatus.EXECUTE,
      updatedAnswerId: id,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const { updatedAnswer } = action.payload as AnswerCommentUseCaseOutput;

    const { updatedAnswers } = updateAnswers(answers, updatedAnswer, attendeeList);

    return {
      ...state,
      updateCommentStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
      updatedAnswerId: '',
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateCommentStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateCommentStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const handleUpdateSubmissionUseCase: UseCaseStateGenerator<UpdateSubmissionUseCaseState> = {
  name: UPDATE_SUBMISSION_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { submission } = action.payload as UpdateSubmissionUseCaseOutput;
    return {
      ...state,
      updateSubmissionStatus: RequestStatus.SUCCESS,
      submission,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      updateSubmissionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateSubmissionStatus: RequestStatus.RESET,
    };
  },
};

export const handleGradingSubmissionUseCase: UseCaseStateGenerator<AutoGradingUseCaseState> = {
  name: GRADING_SUBMISSION_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      gradingSubmissionStatus: RequestStatus.EXECUTE,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const payload = action.payload as GradingSubmissionUseCaseOutput;

    const { submission, attendee } = payload;
    const updatedAttendeeList = updateAttendeeList(state.attendeeList, attendee);

    return {
      ...state,
      gradingSubmissionStatus: RequestStatus.SUCCESS,
      submission: submission,
      attendeeList: updatedAttendeeList,
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      error: error,
      gradingSubmissionStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      gradingSubmissionStatus: RequestStatus.RESET,
    };
  },
};

export const handleCreateAnswerCommentUseCase: UseCaseStateGenerator<UpdateAnswerUseCaseState> = {
  name: CREATE_ANSWER_COMMENT_USE_CASE,
  executing: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const useCaseInput = action.payload as AnswerCommentUseCaseInput;
    const { id } = useCaseInput;

    return {
      ...state,
      updateCommentStatus: RequestStatus.EXECUTE,
      updatedAnswerId: id,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const updatedAnswer = action.payload;

    const { updatedAnswers } = updateAnswers(answers, updatedAnswer, attendeeList);

    return {
      ...state,
      updateCommentStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
      updatedAnswerId: '',
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateCommentStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateCommentStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const handleUpdateAnswerCommentUseCase: UseCaseStateGenerator<UpdateAnswerUseCaseState> = {
  name: UPDATE_ANSWER_COMMENT_USE_CASE,
  executing: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const updatedAnswer = action.payload;
    const { updatedAnswers } = updateAnswers(answers, updatedAnswer, attendeeList);

    return {
      ...state,
      updateCommentStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
      updatedAnswerId: updatedAnswer.id.toString(),
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateCommentStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateCommentStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const handleDeleteAnswerCommentUseCase: UseCaseStateGenerator<UpdateAnswerUseCaseState> = {
  name: DELETE_ANSWER_COMMENT_USE_CASE,
  executing: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const useCaseInput = action.payload as AnswerCommentUseCaseInput;
    const { id } = useCaseInput;

    return {
      ...state,
      updateCommentStatus: RequestStatus.EXECUTE,
      updatedAnswerId: id,
    };
  },
  success: (state: GradingSubmissionState, action: Action): GradingSubmissionState => {
    const { answers, attendeeList } = state;
    const updatedAnswer = action.payload;

    const { updatedAnswers } = updateAnswers(answers, updatedAnswer, attendeeList);

    return {
      ...state,
      updateCommentStatus: RequestStatus.SUCCESS,
      answers: updatedAnswers,
      updatedAnswerId: '',
    };
  },
  error: (state: {}, action: Action) => {
    const { error } = action;
    return {
      ...state,
      updatedAnswerId: '',
      error: error,
      updateCommentStatus: RequestStatus.ERROR,
    };
  },
  reset: (state: GradingSubmissionState): GradingSubmissionState => {
    return {
      ...state,
      updateCommentStatus: RequestStatus.RESET,
      updatedAnswerId: '',
    };
  },
};

export const gradingSubmissionHandlers = [
  handleGetAttendeesUseCase,
  handleGetAttendeeByIdUseCase,
  handleGetFormReleaseByIdUseCase,
  handleGetAssignmentByIdUseCase,
  handleGetSubmissionById,
  handleGetQuestionReleasesUseCase,
  handleGetAnswersUseCase,
  handleGetAttendeeSubmissionUseCase,
  handleUpdateAttendeeGradedStateUseCase,
  handleGradingAnswersUseCase,
  handleAnswerCommentUseCase,
  handleUpdateSubmissionUseCase,
  handleGradingSubmissionUseCase,
  handleCreateAnswerCommentUseCase,
  handleUpdateAnswerCommentUseCase,
  handleDeleteAnswerCommentUseCase,
];
