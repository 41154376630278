import { createReducer } from '@lib/plugin-redux-core';
import {
  CREATE_FOLDER_USE_CASE,
  CREATE_FORM_USE_CASE,
  DELETE_FOLDER_USE_CASE,
  DELETE_FORM_USE_CASE,
  SpaceObjects,
  UpdateFolderUseCaseInput,
  UpdateFormUseCaseInput,
  UPDATE_FOLDER_USE_CASE,
  FolderSummary,
  FormSummary,
  UPDATE_FORM_TITLE_USE_CASE,
  SEARCH_SPACE_OBJECTS_USE_CASE,
  GET_FOLDER_BY_ID_USE_CASE,
  IMPORT_FORM_USE_CASE,
  FIND_FORM_BY_IDS_USE_CASE,
  FIND_GLOBAL_FORMS_USE_CASE,
  GlobalForm,
  GET_TAGS_USE_CASE,
  Tag,
  FormCategory,
  GET_FORM_CATEGORIES_USE_CASE,
} from '@module/form';
import { UseCaseError } from '@digibricks/domains';
import {
  handleCreateFolderUseCase,
  handleCreateFormUseCase,
  handleDeleteFolderUseCase,
  handleDeleteFormUseCase,
  handleFindFormByIdsUseCase,
  handleGetFolderByIdUseCase,
  handleGetFormCategoriesUseCase,
  handleGetGlobalFormsUseCase,
  handleGetTagUseCase,
  handleImportFormUseCase,
  handleSearchSpaceObjectsUseCase,
  handleUpdateFolderUseCase,
  handleUpdateFormTitleUseCase,
} from './space.handler';

export const enum SpacePageStatus {
  EXECUTE,
  SUCCESS,
  ERROR,
  RESET,
}

export type SpacePageInitialState = {
  error?: UseCaseError<any>;
};

//UseCases State ---------------
export type SearchSpaceObjectsUseCaseStates = {
  formsAndFoldersSearchOptions: SpaceObjects;
  searchFormsAndFoldersStatus: SpacePageStatus;
};
export type GetTagsUseCaseState = {
  tags: Tag[];
};

export type GetFormCategoriesUseCaseState = {
  formCategories: FormCategory[];
  getFormCategoriesStatus: SpacePageStatus;
};

export type CreateFolderUseCaseState = {
  createdFolderSummary: FolderSummary;
  createFolderStatus: SpacePageStatus;
};

export type UpdateFolderUseCaseState = {
  updatedFolderSummary: UpdateFolderUseCaseInput;
  updateFolderSummaryStatus: SpacePageStatus;
};

export type GetFolderByIdUseCase = {
  spaceObjects: SpaceObjects;
  getFolderByIdStatus: SpacePageStatus;
};

export type FindFormByIdsUseCase = {
  importingFormIds: string[];
  importingFormId: string;
  findFormByIdsStatus: SpacePageStatus;
  hasErrorForm: boolean;
};

export type DeleteFolderUseCaseState = {
  deletedFolderId: string;
  deleteFolderStatus: SpacePageStatus;
};

export type CreateFormUseCaseState = {
  createdFormSummary: FormSummary;
  createFormStatus: SpacePageStatus;
  newFormId: string;
};

export type ImportFormUseCaseState = {
  importedFormSummary: FormSummary;
  importFormStatus: SpacePageStatus;
};

export type UpdateFormUseCaseState = {
  updatedFormSummary: UpdateFormUseCaseInput;
  updateFormSummaryStatus: SpacePageStatus;
};

export type DeleteFormUseCaseState = {
  deletedFormId: string;
  deleteFormStatus: SpacePageStatus;
};

export type CreateFolderModalState = {
  createFolderModalStatus: boolean;
};

export type GetGlobalFormState = {
  globalForms: GlobalForm[];
  getGlobalFormsStatus: SpacePageStatus;
};

type FolderUseCasesStates = CreateFolderUseCaseState &
  UpdateFolderUseCaseState &
  DeleteFolderUseCaseState &
  GetFolderByIdUseCase &
  FindFormByIdsUseCase;

type FormUseCasesStates = CreateFormUseCaseState &
  UpdateFormUseCaseState &
  DeleteFormUseCaseState &
  ImportFormUseCaseState;

export type SpacePageState = SpacePageInitialState &
  SearchSpaceObjectsUseCaseStates &
  FolderUseCasesStates &
  FormUseCasesStates &
  CreateFolderModalState &
  GetGlobalFormState &
  GetTagsUseCaseState &
  GetFormCategoriesUseCaseState;

export const initialState: SpacePageInitialState = {
  error: null,
};

export const LOADING_SPACE = 'LOADING_SPACE';
export const spaceObjectsReducer = createReducer(initialState, [
  // {
  //   name: LOADING_SPACE,
  //   executing: (state: SpacePageState): SpacePageState => {
  //     return {
  //       ...state,
  //       getFolderByIdStatus: SpacePageStatus.EXECUTE,
  //     };
  //   },
  // },
  {
    name: SEARCH_SPACE_OBJECTS_USE_CASE,
    ...handleSearchSpaceObjectsUseCase,
  },
  {
    name: GET_FOLDER_BY_ID_USE_CASE,
    ...handleGetFolderByIdUseCase,
  },
  {
    name: FIND_GLOBAL_FORMS_USE_CASE,
    ...handleGetGlobalFormsUseCase,
  },
  {
    name: CREATE_FOLDER_USE_CASE,
    ...handleCreateFolderUseCase,
  },
  {
    name: UPDATE_FOLDER_USE_CASE,
    ...handleUpdateFolderUseCase,
  },
  {
    name: DELETE_FOLDER_USE_CASE,
    ...handleDeleteFolderUseCase,
  },
  {
    name: CREATE_FORM_USE_CASE,
    ...handleCreateFormUseCase,
  },
  {
    name: IMPORT_FORM_USE_CASE,
    ...handleImportFormUseCase,
  },
  {
    name: UPDATE_FORM_TITLE_USE_CASE,
    ...handleUpdateFormTitleUseCase,
  },
  {
    name: DELETE_FORM_USE_CASE,
    ...handleDeleteFormUseCase,
  },
  {
    name: FIND_FORM_BY_IDS_USE_CASE,
    ...handleFindFormByIdsUseCase,
  },
  {
    name: GET_TAGS_USE_CASE,
    ...handleGetTagUseCase,
  },
  {
    name: GET_FORM_CATEGORIES_USE_CASE,
    ...handleGetFormCategoriesUseCase,
  },
]);
