import {
  SubmissionWebCached,
  SUBMISSION_WEB_CACHED,
  ASSIGNMENT_CONFIGURATION_CACHED,
  AssignmentConfigurationCached,
  ESSAY_ANSWER_DRAFT_WEB_CACHED,
  EssayAnswerDraftWebCached,
  AssignmentInMemoryWebCached,
  ASSIGNMENT_CACHED_REPOSITORY,
} from '@module/assignment';
import {
  FormReleaseInMemoryWebCached,
  FORM_RELEASE_CACHED_REPOSITORY,
  QUESTION_RELEASE_CACHED_REPOSITORY,
  QuestionReleaseInMemoryCached,
  TAG_WEB_CACHED,
  TagWebCached,
  FORM_CATEGORY_WEB_CACHED,
  FormCategoryWebCached,
} from '@module/form';

export const cachedProviders = [
  {
    provide: TAG_WEB_CACHED,
    useFactory: () => {
      return new TagWebCached();
    },
  },
  {
    provide: FORM_CATEGORY_WEB_CACHED,
    useFactory: () => {
      return new FormCategoryWebCached();
    },
  },
  {
    provide: SUBMISSION_WEB_CACHED,
    useFactory: () => {
      return new SubmissionWebCached();
    },
  },
  {
    provide: ASSIGNMENT_CACHED_REPOSITORY,
    useFactory: () => {
      return new AssignmentInMemoryWebCached();
    },
  },
  {
    provide: QUESTION_RELEASE_CACHED_REPOSITORY,
    useFactory: () => {
      return new QuestionReleaseInMemoryCached();
    },
  },
  {
    provide: FORM_RELEASE_CACHED_REPOSITORY,
    useFactory: () => {
      return new FormReleaseInMemoryWebCached();
    },
  },
  {
    provide: ASSIGNMENT_CONFIGURATION_CACHED,
    useFactory: () => {
      return new AssignmentConfigurationCached();
    },
  },
  {
    provide: ESSAY_ANSWER_DRAFT_WEB_CACHED,
    useFactory: () => {
      return new EssayAnswerDraftWebCached();
    },
  },
];
