import { Action, RequestStatus, UseCaseStateGenerator } from '@lib/plugin-redux-core';
import { UpdateFormInformationUseCaseState } from './tab-info.states';
import { FormEditPageState } from '../form-edit.states';
import { UPDATE_FORM_INFORMATION_USE_CASE } from '@module/form';

export const handleUpdateFormInformationUseCase: UseCaseStateGenerator<UpdateFormInformationUseCaseState> =
  {
    name: UPDATE_FORM_INFORMATION_USE_CASE,
    executing: (state: FormEditPageState): FormEditPageState => {
      return { ...state, updateFormInformationStatus: RequestStatus.EXECUTE };
    },
    success: (state: FormEditPageState): FormEditPageState => {
      return {
        ...state,
        updateFormInformationStatus: RequestStatus.SUCCESS,
      };
    },
    error: (state: FormEditPageState, action: Action): FormEditPageState => {
      const { error } = action;
      return {
        ...state,
        error,
        updateFormInformationStatus: RequestStatus.ERROR,
      };
    },
  };

export const tabInfoHandlers = [handleUpdateFormInformationUseCase];
