import { useCallback, useRef, useState } from 'react';
export var useCommentManager = function useCommentManager(_ref) {
  var comments = _ref.comments,
      setComments = _ref.setComments;

  var _useState = useState(null),
      hoveredCommentId = _useState[0],
      setHoveredCommentId = _useState[1];

  var _useState2 = useState(null),
      popupPosition = _useState2[0],
      setPopupPosition = _useState2[1];

  var _useState3 = useState(false),
      isEditingComment = _useState3[0],
      setIsEditingComment = _useState3[1];

  var _useState4 = useState(''),
      commentValue = _useState4[0],
      setCommentValue = _useState4[1];

  var commentsSectionRef = useRef(null);
  var focusCommentWithActiveId = useCallback(function (id) {
    if (!commentsSectionRef.current) return;
    var commentInput = commentsSectionRef.current.querySelector("input#".concat(id));
    if (!commentInput) return;
    commentInput.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }, []);
  var handleCommentActivated = useCallback(function (commentId) {
    if (commentId) setTimeout(function () {
      return focusCommentWithActiveId(commentId);
    });
  }, [focusCommentWithActiveId]);
  var handleCommentEditClick = useCallback(function (event) {
    var target = event.target;
    var commentId = target.getAttribute('data-comment-id');

    if (commentId) {
      var comment = comments.find(function (c) {
        return c.id === commentId;
      });
      setHoveredCommentId(commentId);
      setPopupPosition({
        top: event.clientY,
        left: event.clientX
      });

      if (comment) {
        setCommentValue(comment.content || '');
      } else {
        disablePopupEditComment();
      }
    }
  }, [comments]);

  var disablePopupEditComment = function disablePopupEditComment() {
    setHoveredCommentId(null);
    setPopupPosition(null);
    setIsEditingComment(false);
    setCommentValue('');
  };

  var handleClickOutside = useCallback(function (event) {
    var _iconElement$classNam;

    var target = event.target;
    var commentId = target.getAttribute('data-comment-id');
    var iconElement = target.closest('.popup-comment');
    var className = target.className;
    var tagName = target.tagName.toLowerCase();
    var classNameCondition = typeof className === 'string' && (className.includes('popup-comment') || className.includes('ant-input') || className.includes('my-comment')) || (iconElement === null || iconElement === void 0 ? void 0 : (_iconElement$classNam = iconElement.className) === null || _iconElement$classNam === void 0 ? void 0 : _iconElement$classNam.includes('popup-comment'));
    var exceptTagNameCases = tagName === 'svg' || tagName === 'textarea' || tagName === 'input';

    if (!commentId) {
      if (!classNameCondition && !exceptTagNameCases) {
        disablePopupEditComment();
      }
    } else if (hoveredCommentId !== commentId) {
      disablePopupEditComment();
    }
  }, [hoveredCommentId]);
  var handleCommentEdit = useCallback(function () {
    setIsEditingComment(true);
    var comment = comments.find(function (c) {
      return c.id === hoveredCommentId;
    });
    setCommentValue((comment === null || comment === void 0 ? void 0 : comment.content) || '');
  }, [comments, hoveredCommentId]);
  var handleCommentDelete = useCallback(function () {
    disablePopupEditComment();
  }, []);
  var handleCommentUpdate = useCallback(function () {
    disablePopupEditComment();
  }, []);
  var handleCancelUpdateComment = useCallback(function () {
    setPopupPosition(null);
    setIsEditingComment(false);
  }, []);
  return {
    comments: comments,
    hoveredCommentId: hoveredCommentId,
    popupPosition: popupPosition,
    isEditingComment: isEditingComment,
    commentValue: commentValue,
    handleCommentActivated: handleCommentActivated,
    handleCommentEditClick: handleCommentEditClick,
    handleClickOutside: handleClickOutside,
    handleCommentEdit: handleCommentEdit,
    handleCommentDelete: handleCommentDelete,
    handleCommentUpdate: handleCommentUpdate,
    handleCancelUpdateComment: handleCancelUpdateComment,
    setCommentValue: setCommentValue,
    setComments: setComments
  };
};