import {
  GetFormCategoriesUseCase,
  IFormCategoryRepository,
  FORM_CATEGORY_REPOSITORY,
  GET_FORM_CATEGORIES_USE_CASE,
} from '@module/form';

export const formCategoryUseCaseProviders = [
  {
    provide: GET_FORM_CATEGORIES_USE_CASE,
    useFactory: (formCategoryRepository: IFormCategoryRepository) => {
      return new GetFormCategoriesUseCase(formCategoryRepository);
    },
    inject: [FORM_CATEGORY_REPOSITORY],
  },
];
